.steps-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  max-width: 1500px;
  margin: 0 auto;
}
.steps-wrapper .step {
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
}
@media (min-width: 1200px) {
  .steps-wrapper .step {
    width: 25%;
  }
  .steps-wrapper .step:nth-child(2n) {
    position: relative;
    top: 160px;
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
  .steps-wrapper .step {
    width: 33%;
  }
  .steps-wrapper .step:nth-child(3n-1) {
    position: relative;
    top: 120px;
  }
}
@media (max-width: 800px) {
  .steps-wrapper .step {
    width: 50%;
    margin-bottom: 50px;
  }
}
@media (max-width: 600px) {
  .steps-wrapper .step {
    width: 70%;
    margin-bottom: 30px;
  }
}
.steps-wrapper .step.disabled {
  filter: grayscale(100%);
  cursor: default;
}
.steps-wrapper .step .svg-wrapper {
  padding-bottom: 110%;
  position: relative;
  overflow: visible;
  transition: transform 200ms cubic-bezier(0.16, 1, 0.3, 1);
}
.steps-wrapper .step .svg-wrapper svg {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.steps-wrapper .step:hover .svg-wrapper {
  transform: translateY(-15px);
}

.container {
  max-width: 1000px;
  margin: 50px auto;
  text-align: left;
  background-color: #fff;
  padding: 48px;
  line-height: 1.4;
}
@media (max-width: 600px) {
  .container {
    padding: 24px 16px;
  }
}
.container.fun {
  border-top: 51px solid #fff;
  border-image: url(/img/flags.svg) 100% 0 0/51px 0 0 repeat;
}

.container.language {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 1000px) {
  .container.language {
    flex-direction: column;
    align-items: center;
  }
}
.container.language .logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container.language .logo-wrapper h1 {
  margin: 0;
}
.container.language .language__img {
  width: 70%;
}
@media (max-width: 1000px) {
  .container.language .language__img {
    width: 100%;
    max-width: 500px;
    order: 1;
    margin-bottom: 40px;
  }
}
.container.language .language__img img {
  width: 100%;
}
.container.language .language__logo {
  width: 80px;
  height: 100%;
  margin-right: 16px;
}
.container.language .language__text {
  width: 100%;
  text-align: left;
  padding-right: 40px;
  line-height: 1.5;
  font-size: 18px;
}
@media (max-width: 1000px) {
  .container.language .language__text {
    order: 2;
    padding-right: 0;
  }
}

.title {
  color: #06c39f;
  font-size: 60px;
  margin-top: 40px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  .title {
    font-size: 32px;
  }
}

.subtitle {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 80px;
  font-size: 15px;
  line-height: 1.5;
  opacity: 0.7;
}

h1 {
  font-size: 2em;
}
@media (max-width: 800px) {
  h1 {
    font-size: 1.5em;
  }
}

h2 {
  font-size: 1.75em;
}
@media (max-width: 800px) {
  h2 {
    font-size: 1.25em;
  }
}

p {
  font-size: 1em;
}
@media (max-width: 800px) {
  p {
    font-size: 0.875em;
  }
}

.text-hint {
  font-size: 0.75em;
  color: #5a5a5a;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.header .header__menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
}
@media (max-width: 600px) {
  .header.header--inner .header__menu {
    width: 100%;
  }
}
.header.header--inner .arrow-left {
  display: none;
}
@media (max-width: 600px) {
  .header.header--inner .arrow-left {
    display: block;
  }
}
@media (max-width: 600px) {
  .header.header--inner .header__logo {
    order: unset;
    display: none;
  }
}
@media (max-width: 600px) {
  .header.header--inner .logo-csc {
    display: none;
  }
}
.header .header__logo {
  margin-right: auto;
}
@media (max-width: 1000px) {
  .header .header__logo {
    width: 120px;
  }
}
@media (max-width: 600px) {
  .header .header__logo {
    width: 100%;
    order: 1;
    margin-top: 80px;
  }
}
.header .header__link {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 900;
  text-decoration: none;
  color: #8886fc;
  margin-left: 24px;
}
.header .header__link:hover {
  color: #5e5ccf;
}
@media (max-width: 800px) {
  .header .header__link {
    font-size: 0;
    margin-left: 4px;
    width: 40px;
    height: 40px;
  }
}

.btn-start {
  text-decoration: none;
  font-weight: 900;
  cursor: pointer;
  margin-left: 20px;
  position: relative;
  top: -25px;
}
@media (max-width: 800px) {
  .btn-start {
    margin-left: 0;
  }
}

.side-right,
.side-left,
.side-top {
  display: inline-block;
  font-size: 15px;
  transition: transform 100ms linear;
  transform-origin: left top;
}

.side-right {
  transform: rotate(-10deg) skewX(-10deg) scaleY(0.864) translate(-3px, 43px);
  color: #fff;
  background-color: #fc6949;
  padding: 8px 12px;
}

.side-left {
  background-color: #fb504d;
  transform: rotate(32deg) skewX(32deg) scaleY(1.6) translate(5px, 11px);
  padding: 8px 8px;
}

.side-top {
  transform-origin: center;
  background-color: #fbae2f;
  transform: rotate(80deg) skewY(-49deg) scaleX(0.69) scaleY(8.1) translate(35px, -7px);
  padding: 8px 8px;
}

.btn-start:hover {
  filter: brightness(1.1);
}
.btn-start:hover .side-right {
  transform: rotate(-10deg) skewX(-10deg) scaleY(0.864) translate(-10px, 37px);
}
.btn-start:hover .side-left {
  transform: rotate(32deg) skewX(32deg) scaleY(1.6) scaleX(0.5) translate(10px, 11px);
}
.btn-start:hover .side-top {
  transform: rotate(80deg) skewY(-49deg) scaleX(0.4) scaleY(8.1) translate(53px, -7px);
}

.button {
  padding: 12px 24px;
  font-size: 1em;
  font-weight: bold;
  background-color: #ff7a63;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.button:hover {
  filter: brightness(1.1);
}

.btn-group .button {
  margin: 0 16px 16px 0;
  display: inline-block;
}
@media (max-width: 600px) {
  .btn-group .button {
    display: block;
    width: 100%;
  }
}

.btn--primary {
  background-color: #99BF4F;
  color: #fff;
  text-transform: uppercase;
}

.btn--secondary {
  background-color: #dedede;
  font-weight: normal;
  color: #000;
}

.btn--big {
  font-size: 1.125em;
  letter-spacing: 2px;
  padding: 16px 32px;
}

.btn--small {
  font-size: 0.825em;
  padding: 8px 16px;
}

/* The Modal (background) */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-image: url("/img/pattern.png");
  background-color: rgba(0, 0, 0, 0.7);
  animation-name: shade;
  animation-duration: 0.2s;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  margin: 100px auto;
  padding: 0;
  max-width: 600px;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@keyframes animatetop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes shade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* The Close Button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close:hover,
.close:focus {
  opacity: 0.7;
}

.modal-header {
  padding: 12px;
  background-color: #fbae2f;
  color: white;
  margin-bottom: 40px;
  position: relative;
}

.modal-body {
  padding: 2px 16px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}
.table thead td {
  font-size: 12px;
  font-weight: bold;
}
.table td {
  font-size: 15px;
  vertical-align: top;
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid #dedede;
}

#start {
  animation-delay: 500ms;
}
#start:hover, #start.stopped {
  animation: none;
}

#start #hand {
  transform: translateY(600px) translateX(150px) scale(0.3);
  transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 300ms;
}

#start:hover #hand {
  transform: translateY(0px) translateX(0px) scale(1);
  transition-delay: 100ms;
}

#start:hover #btn {
  transform: translateX(15px) translateY(-15px);
  transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1);
}

#start #flag {
  transform: translateY(280px) translateX(290px) scale(0);
  transition: all 200ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 200ms;
}

#start:hover #flag {
  transform: translateY(0px) translateX(0px) scale(1);
  transition-delay: 790ms;
}

#start:hover #finger {
  animation: hand-anim-finger 400ms ease-in-out;
  animation-delay: 700ms;
  animation-iteration-count: 1;
  animation-direction: alternate-reverse;
}

@keyframes hand-anim-finger {
  from {
    transform: translateY(0) translateX(0);
  }
  to {
    transform: translateY(15px) translateX(-14px);
  }
}
@keyframes shake {
  5% {
    transform: translateX(-1px);
  }
  10%, 40% {
    transform: translateX(2px);
  }
  15%, 45% {
    transform: translateX(-2px);
  }
  20%, 30% {
    transform: translateX(4px);
  }
  25%, 35% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(0);
  }
}
#perl #disketa {
  transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 200ms;
}

#perl:hover #disketa {
  transform: translate(50px, -35px);
}

#perl #screen__hidder {
  transition: opacity 400ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 700ms;
}

#perl:hover #screen__hidder {
  opacity: 0;
}

#perl #screen--on {
  visibility: hidden;
}

#perl:hover #screen--on {
  visibility: visible;
}

#perl #perl-camel {
  fill: #fff;
  visibility: hidden;
}

#perl:hover #perl-camel {
  visibility: visible;
  transition: fill 900ms steps(4);
  fill: #f87e37;
  transition-delay: 900ms;
}

#lua #lua-top-side,
#lua #top-side-inner,
#lua #lua-left-side,
#lua #left-side-inner,
#lua #lua-right-side,
#lua #right-side-inner {
  transition: transform 500ms cubic-bezier(0.16, 1, 0.3, 1);
}

#lua:hover #lua-top-side,
#lua:hover #top-side-inner {
  transition: transform 500ms cubic-bezier(0.16, 1, 0.3, 1);
  transform: translate(-5px, 52px);
}
#lua:hover #lua-left-side,
#lua:hover #left-side-inner {
  transition: transform 500ms cubic-bezier(0.16, 1, 0.3, 1);
  transform: translate(43px, -22px);
}
#lua:hover #lua-right-side,
#lua:hover #right-side-inner {
  transition: transform 500ms cubic-bezier(0.16, 1, 0.3, 1);
  transform: translate(-48px, -20px);
}

#ocaml:hover #fire-1,
#ocaml:hover #fire-2,
#ocaml:hover #fire-3 {
  animation: ocaml-fire 700ms ease-in-out infinite alternate;
}
#ocaml:hover #pyramide {
  animation: ocaml-pyramide 2s ease-in-out infinite alternate;
}
#ocaml:hover #shadow {
  transform-origin: 250px 500px;
  animation: ocaml-shadow 2s ease-in-out infinite alternate;
}

@keyframes ocaml-fire {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1px);
    filter: drop-shadow(0 -8px 0px rgba(255, 170, 0, 0.95)) drop-shadow(0 -6px 0px rgba(255, 0, 81, 0.95));
  }
}
@keyframes ocaml-pyramide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-30px);
  }
}
@keyframes ocaml-shadow {
  to {
    transform: scale(0.9) translateY(-10px);
    opacity: 0.3;
  }
}
#python-piet:hover #yellow-head {
  animation: python-piet-yellow-head 3s ease-in-out alternate infinite;
}
#python-piet:hover #blue-head {
  animation: python-piet-blue-head 3s ease-in-out alternate infinite;
}
#python-piet:hover #yellow-body {
  animation: python-piet-yellow-body 3s ease-in-out alternate infinite;
}
#python-piet:hover #blue-body {
  animation: python-piet-blue-body 3s ease-in-out alternate infinite;
}
#python-piet:hover #colors {
  animation: python-piet-colors 2000ms steps(4, end) alternate infinite;
}

@keyframes python-piet-blue-head {
  to {
    transform: translateX(30px);
  }
}
@keyframes python-piet-yellow-body {
  to {
    transform: translateY(15px);
  }
}
@keyframes python-piet-blue-body {
  to {
    transform: translateX(10px);
  }
}
@keyframes python-piet-yellow-head {
  to {
    transform: translateX(-30px);
  }
}
@keyframes python-piet-colors {
  to {
    filter: hue-rotate(360deg);
  }
}
#kotlin #snow {
  opacity: 0.95;
  transition: all 1000ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 500ms;
  transform-origin: 260px 255px;
}
#kotlin #ball-shine {
  transition: all 1000ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 500ms;
}

#kotlin:hover {
  animation: kotlin-shake 700ms alternate;
}
#kotlin:hover #snow {
  opacity: 0.25;
  transform: rotate(15deg);
}
#kotlin:hover #ball-shine {
  opacity: 0.2;
}

@keyframes kotlin-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0) rotate(-2deg);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0) rotate(5deg);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0) rotate(-10deg);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0) rotate(10deg);
  }
}
#vim .vim-side {
  stroke: #ffffff;
  stroke-width: 2;
  stroke-dasharray: 200 80;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-opacity: 0;
  transition: all 500ms linear;
  stroke-dashoffset: 3000;
}

#vim:hover .vim-side {
  animation: vim-dash 10s linear alternate infinite;
  stroke-opacity: 1;
  fill: #5e5ccf;
  transition: all 500ms linear;
}
#vim:hover .vim-shadow {
  fill: #5e5ccf;
  transition: all 500ms linear;
}

@keyframes vim-dash {
  to {
    stroke-dashoffset: 800;
  }
}
#JS:hover #blick-1,
#JS:hover #blick-2,
#JS:hover #blick-3 {
  animation: js-blick 500ms linear alternate infinite;
}
#JS:hover #blick-1 {
  transform-origin: 188px 590px;
  animation-delay: 300ms;
}
#JS:hover #blick-2 {
  transform-origin: 36px 362px;
  animation-delay: 600ms;
}
#JS:hover #blick-3 {
  transform-origin: 312px 270px;
  animation-delay: 900ms;
}
#JS:hover #message {
  transform-origin: 230px 290px;
  animation: js-message 1.2s linear alternate infinite;
}

@keyframes js-message {
  20% {
    transform: scale(1.05) translateY(-10px) translateX(-5px);
  }
  50% {
    transform: scale(1) translateY(0) translateX(0);
  }
}
@keyframes js-blick {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  60% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}
#julia:hover #peace-1,
#julia:hover #peace-2,
#julia:hover #peace-3,
#julia:hover #peace-4,
#julia:hover #peace-5,
#julia:hover #peace-6,
#julia:hover #peace-7,
#julia:hover #peace-8 {
  animation: julia-peace 1.4s ease-in-out infinite;
}
#julia:hover #peace-1 {
  animation-delay: 100ms;
}
#julia:hover #peace-2 {
  animation-delay: 200ms;
}
#julia:hover #peace-3 {
  animation-delay: 300ms;
}
#julia:hover #peace-4 {
  animation-delay: 400ms;
}
#julia:hover #peace-5 {
  animation-delay: 500ms;
}
#julia:hover #peace-6 {
  animation-delay: 600ms;
}
#julia:hover #peace-7 {
  animation-delay: 700ms;
}
#julia:hover #peace-8 {
  animation-delay: 800ms;
}

@keyframes julia-peace {
  20% {
    transform: translateY(-30px);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(0px);
  }
}
#fsharp .svg-wrapper {
  transition-delay: 800ms;
}
#fsharp #labirint {
  transition: all 750ms cubic-bezier(0.16, 1, 0.3, 1);
}

#fsharp:hover #labirint {
  transform: translateX(-228px) translateY(-18px);
  transition: all 750ms cubic-bezier(0.16, 1, 0.3, 1);
}
#fsharp:hover .svg-wrapper {
  transition-delay: 800ms;
}

#coq #coq-crow {
  transform-origin: 90px 205px;
  transform: scale(0) translateX(100px) translateY(-120px);
}
#coq #coq-rooster {
  transition: all 500ms linear;
}
#coq #coq-head {
  animation: none;
}

#coq:hover #coq-rooster {
  transform: translateY(-70px);
}
#coq:hover #coq-head {
  transform-origin: 164px 130px;
  animation: coq-head 1200ms cubic-bezier(0.16, 1, 0.3, 1) 500ms alternate infinite;
}
#coq:hover #coq-crow {
  animation: coq-crow 2400ms cubic-bezier(0.16, 1, 0.3, 1) 500ms infinite;
}

@keyframes coq-head {
  to {
    transform: rotate(10deg) translateX(12px);
  }
}
@keyframes coq-crow {
  0% {
    transform: scale(0) translateX(70px) translateY(-250px);
    opacity: 0;
  }
  40% {
    transform: scale(1) translateX(10px) translateY(-70px);
    opacity: 1;
  }
  70% {
    transform: scale(1) translateX(10px) translateY(-70px);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) translateX(10px) translateY(-150px);
    opacity: 0;
  }
}
#finish #finish-tree {
  transform: translateY(420px) translateX(180px) scale(0.3);
  transition: transform 500ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 300ms;
}

#finish:hover #finish-tree {
  transform: translateY(0px) translateX(0px) scale(1);
  transition-delay: 100ms;
}

#finish:hover #finish-btn {
  transform: translateX(-16px) translateY(-12px);
  transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1);
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background-color: #D6E2FA;
  background-image: url("/img/bg.jpg");
  text-align: center;
  padding: 20px 32px;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
@media (max-width: 600px) {
  body {
    padding: 20px 12px;
  }
}

img {
  max-width: 100%;
}