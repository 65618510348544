#vim .vim-side {
  stroke: #ffffff;
  stroke-width: 2;
  stroke-dasharray: 200 80;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-opacity: 0;
  transition: all 500ms linear;
  stroke-dashoffset: 3000;
}

#vim:hover {
  .vim-side {
    animation: vim-dash 10s linear alternate infinite;
    stroke-opacity: 1;
    fill: #5e5ccf;
    transition: all 500ms linear;
  }

  .vim-shadow {
    fill: #5e5ccf;
    transition: all 500ms linear;
  }
}

@keyframes vim-dash {
  to {
    stroke-dashoffset: 800;
  }
}
