@import "variables";
@import "grid";
@import "language";
@import "typography";
@import "header";
@import "btns";
@import "modal";
@import "table";
@import "animation/start";
@import "animation/perl";
@import "animation/lua";
@import "animation/ocaml";
@import "animation/python-piet";
@import "animation/kotlin";
@import "animation/vim";
@import "animation/JS";
@import "animation/julia";
@import "animation/f-sharp";
@import "animation/coq";
@import "animation/finish";

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background-color: #D6E2FA;
  background-image: url('/img/bg.jpg');
  text-align: center;
  padding: 20px 32px;
  font-family: 'Montserrat', sans-serif;
  margin: 0;

  @media (max-width: 600px) {
    padding: 20px 12px;
  }
}

img {
  max-width: 100%;
}
