@import "btn-start";

.button {
  padding: 12px 24px;
  font-size: 1em;
  font-weight: bold;
  background-color: #ff7a63;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  &:hover {
    filter: brightness(1.1);
  }
}

.btn-group {
  .button {
    margin: 0 16px 16px 0;
    display: inline-block;
    @media (max-width: 600px) {
      display: block;
      width: 100%;
    }
  }
}

.btn--primary {
  background-color: #99BF4F;
  color: #fff;
  text-transform: uppercase;
}

.btn--secondary {
  background-color: #dedede;
  font-weight: normal;
  color: #000;
}

.btn--big {
  font-size: 1.125em;
  letter-spacing: 2px;
  padding: 16px 32px;
}
.btn--small {
  font-size: 0.825em;
  padding: 8px 16px;
}
