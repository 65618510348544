#fsharp {
  .svg-wrapper {
    transition-delay: 800ms;
  }
  #labirint {
    transition: all 750ms $cubic-bezier;
  }
}
#fsharp:hover {
  #labirint {
    transform: translateX(-228px) translateY(-18px);
    transition: all 750ms $cubic-bezier;
  }
  .svg-wrapper {
    transition-delay: 800ms;
  }
}
