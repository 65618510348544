#start {
  animation-delay: 500ms;
  &:hover,
  &.stopped {
    animation: none;
  }
}

#start #hand {
  transform: translateY(600px) translateX(150px) scale(0.3);
  transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 300ms;
}

#start:hover #hand {
  transform: translateY(0px) translateX(0px) scale(1);
  transition-delay: 100ms;
}

#start:hover #btn {
  transform: translateX(15px) translateY(-15px);
  transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1);
}

#start #flag {
  transform: translateY(280px) translateX(290px) scale(0);
  transition: all 200ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 200ms;
}

#start:hover #flag {
  transform: translateY(0px) translateX(0px) scale(1);
  transition-delay: 790ms;
}

#start:hover #finger {
  animation: hand-anim-finger 400ms ease-in-out;
  animation-delay: 700ms;
  animation-iteration-count: 1;
  animation-direction: alternate-reverse;
}


@keyframes hand-anim-finger {
  from {
    transform: translateY(0) translateX(0)
  }
  to {
    transform: translateY(15px) translateX(-14px)
  }
}

@keyframes shake {
  5% {
    transform: translateX(-1px);
  }
  10%, 40% {
    transform: translateX(2px);
  }
  15%, 45% {
    transform: translateX(-2px);
  }
  20%, 30% {
    transform: translateX(4px);
  }
  25%, 35% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(0);
  }
}
