#julia:hover {
  #peace-1,
  #peace-2,
  #peace-3,
  #peace-4,
  #peace-5,
  #peace-6,
  #peace-7,
  #peace-8 {
    animation: julia-peace 1.4s ease-in-out infinite;
  }
#peace-1 {animation-delay: 100ms}
#peace-2 {animation-delay: 200ms}
#peace-3 {animation-delay: 300ms}
#peace-4 {animation-delay: 400ms}
#peace-5 {animation-delay: 500ms}
#peace-6 {animation-delay: 600ms}
#peace-7 {animation-delay: 700ms}
#peace-8 {animation-delay: 800ms}
}


@keyframes julia-peace {
  20% {transform: translateY(-30px)}
  40% {transform: translateY(10px)}
  60% {transform: translateY(0px)}
}
