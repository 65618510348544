.title {
  color: #06c39f;
  font-size: 60px;
  margin-top: 40px;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 32px;
  }
}

.subtitle {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 80px;
  font-size: 15px;
  line-height: 1.5;
  opacity: 0.7;
}

h1 {
  font-size: 2em;
  @media (max-width: 800px) {
    font-size: 1.5em;
  }
}

h2 {
  font-size: 1.75em;
  @media (max-width: 800px) {
    font-size: 1.25em;
  }
}

p {
  font-size: 1em;
  @media (max-width: 800px) {
    font-size: 0.875em;
  }
}

.text-hint {
  font-size: 0.75em;
  color: #5a5a5a;
}
