#finish #finish-tree {
  transform: translateY(420px) translateX(180px) scale(0.3);
  transition: transform 500ms cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 300ms;
}

#finish:hover #finish-tree {
  transform: translateY(0px) translateX(0px) scale(1);
  transition-delay: 100ms;
}

#finish:hover #finish-btn {
  transform: translateX(-16px) translateY(-12px);
  transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1);
}
