.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .header__menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;
  }

  &.header--inner {
    .header__menu {
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    .arrow-left {
      display: none;
      @media (max-width: 600px) {
        display: block;
      }
    }

    .header__logo {
      @media (max-width: 600px) {
        order: unset;
        display: none;
      }
    }

    .logo-csc {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }


  .header__logo {
    margin-right: auto;
    @media (max-width: 1000px) {
      width: 120px;
    }
    @media (max-width: 600px) {
      width: 100%;
      order: 1;
      margin-top: 80px;
    }
  }

  .header__link {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 900;
    text-decoration: none;
    color: #8886fc;
    margin-left: 24px;

    &:hover {
      color: #5e5ccf;
    }

    @media (max-width: 800px) {
      font-size: 0;
      margin-left: 4px;
      width: 40px;
      height: 40px;
    }
  }
}
