#perl #disketa {
  transition: transform 600ms $cubic-bezier;
  transition-delay: 200ms;
}

#perl:hover #disketa {
  transform: translate(50px, -35px);
}

#perl #screen__hidder {
  transition: opacity 400ms $cubic-bezier;
  transition-delay: 700ms;
}

#perl:hover #screen__hidder {
  opacity: 0;
}

#perl #screen--on {
  visibility: hidden;
}

#perl:hover #screen--on {
  visibility: visible;
}

#perl #perl-camel {
  fill: #fff;
  visibility: hidden;
}

#perl:hover #perl-camel {
  visibility: visible;
  transition: fill 900ms steps(4);
  fill: #f87e37;
  transition-delay: 900ms;
}
