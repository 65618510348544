.container.language {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      margin: 0;
    }
  }

  .language__img {
    width: 70%;
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 500px;
      order: 1;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
    }
  }

  .language__logo {
    width: 80px;
    height: 100%;
    margin-right: 16px;
  }

  .language__text {
    width: 100%;
    text-align: left;
    padding-right: 40px;
    line-height: 1.5;
    font-size: 18px;
    @media (max-width: 1000px) {
      order: 2;
      padding-right: 0;
    }
  }
}
