#kotlin {
  #snow {
    opacity: 0.95;
    transition: all 1000ms $cubic-bezier;
    transition-delay: 500ms;
    transform-origin: 260px 255px;
  }
  #ball-shine {
    transition: all 1000ms $cubic-bezier;
    transition-delay: 500ms;
  }
}

#kotlin:hover {
  animation: kotlin-shake 700ms alternate;

  #snow {
    opacity: 0.25;
    transform: rotate(15deg);
  }
  #ball-shine {
    opacity: 0.2;
  }
}

@keyframes kotlin-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0) rotate(-2deg);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0) rotate(5deg);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0) rotate(-10deg);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0) rotate(10deg);
  }
}
