.btn-start {
  text-decoration: none;
  font-weight: 900;
  cursor: pointer;
  margin-left: 20px;
  position: relative;
  top: -25px;
  @media (max-width: 800px) {
    margin-left: 0;
  }
}

.side-right,
.side-left,
.side-top {
  display: inline-block;
  font-size: 15px;
  transition: transform 100ms linear;
  transform-origin: left top;
}

.side-right {
  transform: rotate(-10deg) skewX(-10deg) scaleY(.864) translate(-3px, 43px);
  color: #fff;
  background-color: #fc6949;
  padding: 8px 12px;
}

.side-left {
  background-color: #fb504d;
  transform: rotate(32deg) skewX(32deg) scaleY(1.6) translate(5px, 11px);
  padding: 8px 8px;
}

.side-top {
  transform-origin: center;
  background-color: #fbae2f;
  transform: rotate(80deg) skewY(-49deg) scaleX(.69) scaleY(8.1) translate(35px,-7px);
  padding: 8px 8px;
}

.btn-start:hover {

  filter: brightness(1.1);

  .side-right {
    transform: rotate(-10deg) skewX(-10deg) scaleY(.864) translate(-10px,37px);
  }

  .side-left {
    transform: rotate(32deg) skewX(32deg) scaleY(1.6) scaleX(.5) translate(10px,11px);
  }

  .side-top {
    transform: rotate(80deg) skewY(-49deg) scaleX(.4)  scaleY(8.1) translate(53px,-7px);
  }

}
