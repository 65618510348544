/* The Modal (background) */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-image: url("/img/pattern.png");
  background-color: rgba(0,0,0, 0.7);
  animation-name: shade;
  animation-duration: 0.2s;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  margin: 100px auto;
  padding: 0;
  max-width: 600px;
  animation-name: animatetop;
  //animation-delay: 200ms;
  animation-duration: 0.4s;
}

/* Add Animation */
@keyframes animatetop {
  from {transform: translateY(100px); opacity:0}
  to {transform: translateY(0); opacity:1}
}

@keyframes shade {
  from {opacity:0}
  to {opacity:1}
}

/* The Close Button */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close:hover,
.close:focus {
  opacity: 0.7;
}

.modal-header {
  padding: 12px;
  background-color: #fbae2f;
  color: white;
  margin-bottom: 40px;
  position: relative;
}

.modal-body {padding: 2px 16px;}

