#ocaml:hover {
  #fire-1,
  #fire-2,
  #fire-3 {
    animation: ocaml-fire 700ms ease-in-out infinite alternate;
  }
  #pyramide {
    animation: ocaml-pyramide 2s ease-in-out infinite alternate;
  }
  #shadow {
    transform-origin: 250px 500px;
    animation: ocaml-shadow 2s ease-in-out infinite alternate;
  }
}

@keyframes ocaml-fire {
  from {transform: translateY(0)}
  to {
    transform: translateY(-1px);
    filter: drop-shadow(0 -8px 0px rgba(255, 170, 0, .95)) drop-shadow(0 -6px 0px rgba(255, 0, 81, .95));
  }
}

@keyframes ocaml-pyramide {
  from {transform: translateY(0)}
  to {
    transform: translateY(-30px);
  }
}

@keyframes ocaml-shadow {
  to {
    transform: scale(0.9) translateY(-10px);
    opacity: 0.3;
  }
}
