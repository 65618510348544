.table {
  width: 100%;
  border-collapse: collapse;

  thead td{
    font-size: 12px;
    font-weight: bold;
  }

  td {
    font-size: 15px;
    vertical-align: top;
    padding: 8px 8px 8px 0;
    border-bottom: 1px solid #dedede;
  }
}
