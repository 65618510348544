#python-piet:hover {
  #yellow-head {
    animation: python-piet-yellow-head 3s ease-in-out alternate infinite;
  }
  #blue-head {
    animation: python-piet-blue-head 3s ease-in-out alternate infinite;
  }
  #yellow-body {
    animation: python-piet-yellow-body 3s ease-in-out alternate infinite;
  }
  #blue-body {
    animation: python-piet-blue-body 3s ease-in-out alternate infinite;
  }
  #colors {
    animation: python-piet-colors 2000ms steps(4, end) alternate infinite;
  }
}

@keyframes python-piet-blue-head {
  to {
    transform: translateX(30px);
  }
}

@keyframes python-piet-yellow-body {
  to {
    transform: translateY(15px);
  }
}
@keyframes python-piet-blue-body {
  to {
    transform: translateX(10px);
  }
}
@keyframes python-piet-yellow-head {
  to {
    transform: translateX(-30px);
  }
}

@keyframes python-piet-colors {
  to {
    filter: hue-rotate(360deg);
  }
}
