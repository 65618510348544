.steps-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  max-width: 1500px;
  margin: 0 auto;

  .step {
    cursor: pointer;
    padding: 20px;
    box-sizing: border-box;

    @media (min-width: 1200px) {
      width: 25%;

      &:nth-child(2n),
      {
        position: relative;
        top: 160px;
      }
    }
    @media (min-width: 800px) and (max-width: 1200px) {
      width: 33%;
      &:nth-child(3n-1) {
        position: relative;
        top: 120px;
      }
    }
    @media (max-width: 800px) {
      width: 50%;
      margin-bottom: 50px;
    }
    @media (max-width: 600px) {
      width: 70%;
      margin-bottom: 30px;
    }

    &.disabled {
      filter: grayscale(100%);
      cursor: default;
    }

    .svg-wrapper {
      padding-bottom: 110%;
      position: relative;
      overflow: visible;
      transition: transform 200ms $cubic-bezier;

      svg {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &:hover .svg-wrapper {
      transform: translateY(-15px);
    }
  }
}

.container {
  max-width: 1000px;
  margin: 50px auto;
  text-align: left;
  background-color: #fff;
  padding: 48px;
  line-height: 1.4;
  @media (max-width: 600px) {
    padding: 24px 16px;
  }

  &.fun {
    border-top: 51px solid #fff;
    border-image: url(/img/flags.svg) 100% 0 0 / 51px 0 0 repeat;
  }
}


