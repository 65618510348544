#JS:hover {

  #blick-1,
  #blick-2,
  #blick-3 {
    animation: js-blick 500ms linear alternate infinite;
  }

  #blick-1 {
    transform-origin: 188px 590px;
    animation-delay: 300ms;
  }

  #blick-2 {
    transform-origin: 36px 362px;
    animation-delay: 600ms;
  }

  #blick-3 {
    transform-origin: 312px 270px;
    animation-delay: 900ms;
  }

  #message {
    transform-origin: 230px 290px;
    animation: js-message 1.2s linear alternate infinite;
  }
}

@keyframes js-message {
  20% {
    transform: scale(1.05) translateY(-10px) translateX(-5px);
  }
  50% {
    transform: scale(1) translateY(0) translateX(0);
  }
}

@keyframes js-blick {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  60% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}
