#coq {
  #coq-crow {
    transform-origin: 90px 205px;
    transform: scale(0) translateX(100px) translateY(-120px);
  }
  #coq-rooster {
    transition: all 500ms linear;
  }
  #coq-head {
    animation: none;
  }
}

#coq:hover {
  #coq-rooster {
    transform: translateY(-70px);
  }

  #coq-head {
    transform-origin: 164px 130px;
    animation: coq-head 1200ms $cubic-bezier 500ms alternate infinite;
  }

  #coq-crow {
    animation: coq-crow 2400ms $cubic-bezier 500ms infinite;
  }
}

@keyframes coq-head {
  to {
    transform: rotate(10deg) translateX(12px);
  }
}

@keyframes coq-crow {
  0% {
    transform: scale(0) translateX(70px) translateY(-250px);
    opacity: 0;
  }
  40% {
    transform: scale(1) translateX(10px) translateY(-70px);
    opacity: 1;
  }
  70% {
    transform: scale(1) translateX(10px) translateY(-70px);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) translateX(10px) translateY(-150px);
    opacity: 0;
  }
}
