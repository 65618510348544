#lua {
  #lua-top-side,
  #top-side-inner,
  #lua-left-side,
  #left-side-inner,
  #lua-right-side,
  #right-side-inner {
    transition: transform 500ms $cubic-bezier;
  }
}

#lua:hover {
  #lua-top-side,
  #top-side-inner {
  transition: transform 500ms $cubic-bezier;
    transform: translate(-5px, 52px);
  }

  #lua-left-side,
  #left-side-inner {
    transition: transform 500ms $cubic-bezier;
    transform: translate(43px, -22px);
  }

  #lua-right-side,
  #right-side-inner {
    transition: transform 500ms $cubic-bezier;
    transform: translate(-48px, -20px);
  }
}
